#basic-navbar-nav {
    background-color: #283655;
    color: #D0E1F9;
    font-family: 'Ubuntu', sans-serif;
    
}
.navbar-expand-lg {
    background-color: #283655;
}

.nav-link {
    color: #D0E1F9 !important;
}

.Navbar-Brand {
    font-family: 'Permanent Marker', cursive;
    font-size: 30px;
}
