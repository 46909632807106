.card {
    background-size: cover;
    background-position: center;
    background-clip: content-box;
    border: 6px solid white;
    height: 400px;
    width: 400px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
  }
  
  .card .fa-spinner {
    font-size: 180px;
    position: absolute;
    left: 25%;
    top: 25%;
  }
  
  #cardImg {
   padding-left: 50px !important;
   padding-right: 50px !important;
  }

.card-title {
  text-align: center;
}

