#home-img {
    padding: 0px 0px 0px 0px !important;   
}

.page-footer {
    position: absolute;
 bottom: 0;
 width: 100%;
}

